<template>
  <div
    class="modal fade"
    ref="modal-transfer-input"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-sm modal-helper">
      <div class="modal-content">
        <div class="modal-title">輸入匯款帳號後五碼</div>
        <div class="helper-content">
          <div class="account-input">
            <input
              type="tel"
              placeholder="請輸入匯款帳號後五碼"
              v-model="bankFiveCode"
            />
          </div>
        </div>
        <div class="modal-btn-block">
          <div class="modal-btn cancel" data-dismiss="modal">取消</div>
          <div class="modal-btn" @click.prevent="confirm">
            確定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bankno: {
      type: [String, Number],
      required: false,
      default() {
        return null;
      }
    }
  },
  computed: {
    bankFiveCode: {
      get() {
        return this.bankno;
      },
      set(val) {
        this.$emit('update:bankno', val);
      }
    }
  },
  methods: {
    confirm() {
      if (this.bankno && this.bankno.length === 5) {
        this.$emit('confirm', this.bankno);
      }
    },
    showModal() {
      $(this.$refs['modal-transfer-input']).modal('show');
    },
    hideModal() {
      $(this.$refs['modal-transfer-input']).modal('hide');
    }
  }
};
</script>
