var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"product-info-wrapper"},[_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"container"},[_c('GroupBoard',{attrs:{"group":_vm.groupInfo}})],1)]),_vm._l((_vm.groupInfo.member_group_buying_orders),function(order,index){return _c('div',{key:("orderitem" + (order.id)),staticClass:"content-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"product-item"},[_c('div',{staticClass:"productimg"},[_c('img',{attrs:{"src":_vm.groupInfo.cover,"alt":""}})]),_c('div',{staticClass:"product-info-wrap"},[_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(order.member.name))])]),_c('div',{staticClass:"btn-item-wrap"},[_c('div',{staticClass:"btn-item"},[_c('div',{staticClass:"btn-cta",class:{ undo: order.payment_status === 1 },attrs:{"data-order-index":index,"data-order-id":order.id,"data-order-payment-status":order.payment_status,"data-payment-method":order.payment_method_name},on:{"click":_vm.changePaymentStatus}},[_vm._v(" "+_vm._s(order.payment_status_display)+" ")]),_c('div',{staticClass:"helper",on:{"click":_vm.showHelperPaymentMsg}})]),_c('div',{staticClass:"btn-item"},[_c('div',{staticClass:"btn-cta",class:{ undo: order.shipping_status === 1 },attrs:{"data-order-index":index,"data-order-id":order.id,"data-order-shipping-status":order.shipping_status},on:{"click":_vm.changeShippingStatus}},[_vm._v(" "+_vm._s(order.shipping_status_display)+" ")]),_c('div',{staticClass:"helper",on:{"click":_vm.showHelperDeliveryMsg}})])])])])]),_c('router-link',{staticClass:"more-details",attrs:{"to":{
          name: 'FollowedOrderDetail',
          params: {
            group_id: _vm.groupId,
            order_id: order.id
          }
        }}})],1)})],2),(
      _vm.groupInfo.wechat_pay_code &&
        _vm.groupInfo.member_group_buying_orders[0].payment_method_name !==
          'ECPay'
    )?_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"passbook-data"},[_c('div',{staticClass:"pass-title"},[_vm._v("匯款資料")]),_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":_vm.groupInfo.wechat_pay_code,"alt":""}})])])])]):_vm._e(),_c('BankCodeInputModal',{ref:"bankCodeModal",attrs:{"bankno":_vm.bankno},on:{"update:bankno":function($event){_vm.bankno=$event},"confirm":_vm.markOrderAsPaid}}),_c('MessageAlert',{attrs:{"show":_vm.showMessageModal,"msgTitle":_vm.msgTitle,"msgContent":_vm.msgContent},on:{"close-modal":_vm.confirmMsg}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }