<template>
  <div class="main-content">
    <div class="product-info-wrapper">
      <div class="content-block">
        <div class="container">
          <GroupBoard :group="groupInfo"></GroupBoard>
        </div>
      </div>
      <div
        class="content-block"
        v-for="(order, index) in groupInfo.member_group_buying_orders"
        :key="`orderitem${order.id}`"
      >
        <div class="container">
          <div class="product-item">
            <div class="productimg">
              <img :src="groupInfo.cover" alt="" />
            </div>
            <div class="product-info-wrap">
              <div class="product-info">
                <div class="user-name">{{ order.member.name }}</div>
              </div>
              <div class="btn-item-wrap">
                <div class="btn-item">
                  <div
                    class="btn-cta"
                    :class="{ undo: order.payment_status === 1 }"
                    :data-order-index="index"
                    :data-order-id="order.id"
                    :data-order-payment-status="order.payment_status"
                    :data-payment-method="order.payment_method_name"
                    @click="changePaymentStatus"
                  >
                    {{ order.payment_status_display }}
                  </div>
                  <div class="helper" @click="showHelperPaymentMsg"></div>
                </div>
                <div class="btn-item">
                  <div
                    class="btn-cta"
                    :class="{ undo: order.shipping_status === 1 }"
                    :data-order-index="index"
                    :data-order-id="order.id"
                    :data-order-shipping-status="order.shipping_status"
                    @click="changeShippingStatus"
                  >
                    {{ order.shipping_status_display }}
                  </div>
                  <div class="helper" @click="showHelperDeliveryMsg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link
          class="more-details"
          :to="{
            name: 'FollowedOrderDetail',
            params: {
              group_id: groupId,
              order_id: order.id
            }
          }"
        ></router-link>
      </div>
    </div>
    <div
      class="content-block"
      v-if="
        groupInfo.wechat_pay_code &&
          groupInfo.member_group_buying_orders[0].payment_method_name !==
            'ECPay'
      "
    >
      <div class="container">
        <div class="passbook-data">
          <div class="pass-title">匯款資料</div>
          <div class="img-wrapper">
            <img :src="groupInfo.wechat_pay_code" alt="" />
          </div>
        </div>
      </div>
    </div>
    <BankCodeInputModal
      ref="bankCodeModal"
      :bankno.sync="bankno"
      @confirm="markOrderAsPaid"
    />
    <MessageAlert
      :show="showMessageModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
import {
  getMyFollowedGroupList,
  memberGroupCheckout
} from '@/api/group-buying';
import GroupBoard from '@/components/GroupList/GroupBoard';
import { updateMemberGroupBuyingOrderStatus } from '@/api/group-buying';
import MessageAlert from '@/components/Modals/ModalMessageAlert';
import { isEmpty, get } from 'lodash';
import { getSetting } from '@/api/member';
import BankCodeInputModal from '@/components/Modals/BankCodeInputModal';
import LineLIFFApp from '@/mixins/line-liff-app';

export default {
  mixins: [LineLIFFApp],
  components: {
    GroupBoard,
    MessageAlert,
    BankCodeInputModal
  },
  data() {
    return {
      groupInfo: {},
      bankno: null,
      orderId: null,
      paymentMethodName: null,
      //for message
      showMessageModal: false,
      msgTitle: null,
      msgContent: null
    };
  },
  computed: {
    groupId() {
      let groupId = this.$route?.params?.group_id;

      return groupId !== undefined ? groupId : this.$route?.query?.groupId;
    },
    isDeliveryTypePersonally() {
      return isEmpty(this.groupInfo)
        ? false
        : this.groupInfo.delivery_type === 1;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetch();
        }
      }
    }
  },
  methods: {
    //for line liff
    loginCallback() {
      this.fetch();
      getSetting('default_follow_group_settings').then(data => {
        this.bankno = get(data, '3.bankno');
      });
    },
    fetch() {
      getMyFollowedGroupList(this.groupId).then(data => {
        this.groupInfo = data;
      });
    },
    changePaymentStatus(e) {
      const orderIndex = e.target.dataset.orderIndex;
      const paymentStatus = e.target.dataset.orderPaymentStatus;

      this.orderId = e.target.dataset.orderId;
      this.paymentMethodName = e.target.dataset.paymentMethod;

      //不是未付款狀態了無法變更
      if (paymentStatus != 1) {
        return;
      }

      //尚未付款領貨不能付款
      if (this.groupInfo.status != 3) {
        this.showGroupNotValidMsg();
        return;
      }

      if (paymentStatus == 1) {
        //綠界付款導向
        if (this.paymentMethodName === 'ECPay') {
          memberGroupCheckout(this.orderId).then(data => {
            if (data?.html) {
              document.write(data.html);
            }
          });
          return;
        }

        this.paymentMethodName === 'Transfer'
          ? this.showUpdateBankNo()
          : this.markOrderAsPaid();
      }
    },
    showUpdateBankNo() {
      this.$refs.bankCodeModal.showModal();
    },
    hideUpdateBankNo() {
      this.$refs.bankCodeModal.hideModal();
    },
    markOrderAsPaid() {
      if (isEmpty(this.orderId)) {
        return;
      }

      //變更狀態為已付款
      updateMemberGroupBuyingOrderStatus({
        member_group_buying_order_id: this.orderId,
        payment_status: 2,
        bankno: this.bankno
      }).then(() => {
        this.hideUpdateBankNo();
        this.resetOrder();
        this.fetch();
      });
    },
    changeShippingStatus(e) {
      const orderIndex = e.target.dataset.orderIndex;
      const orderId = e.target.dataset.orderId;
      const shippingStatus = e.target.dataset.orderShippingStatus;

      if (shippingStatus == 3) {
        return;
      }

      if (shippingStatus != 2) {
        this.showNotShippedYet();
        return;
      }

      updateMemberGroupBuyingOrderStatus({
        member_group_buying_order_id: orderId,
        shipping_status: 3
      }).then(() => {
        this.fetch();
      });
    },
    confirmMsg() {
      this.reset();
    },
    resetOrder() {
      this.orderId = null;
      this.bankno = null;
      this.paymentMethodName = null;
    },
    reset() {
      this.showMessageModal = false;
      this.msgTitle = null;
      this.msgContent = null;
    },
    showGroupNotValidMsg() {
      this.showMessageModal = true;
      this.msgTitle = '無法變更付款狀態';
      this.msgContent = '團購尚未付款領貨';
    },
    showHelperPaymentMsg() {
      this.showMessageModal = true;
      this.msgTitle = '支付說明';
      this.msgContent =
        '如團友已付款，點選此按鈕會顯示【確認已付款】，團友的頁面也會顯示【確認已付款】';
    },
    showHelperDeliveryMsg() {
      this.showMessageModal = true;
      this.msgTitle = '出貨說明';
      this.msgContent =
        '如已出貨，點選此按鈕會顯示【已出貨】，團友的頁面也會顯示【已出貨】';
    },
    showNotShippedYet() {
      this.showMessageModal = true;
      this.msgTitle = '無法確認收貨狀態';
      this.msgContent = '團主尚未出貨';
    }
  }
};
</script>
