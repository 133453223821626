<template>
  <div class="top-block">
    <div class="cta-block">
      <slot></slot>
    </div>
    <ul class="group-datas">
      <li item="跟團人數" v-if="memberCount !== undefined">
        <div class="text">{{ memberCount }}人</div>
      </li>
      <li item="已購數量" v-if="itemsCount !== undefined">
        <div class="text">{{ itemsCount }}份</div>
      </li>
      <li item="總金額" v-if="totalPrice !== undefined">
        <div class="text">${{ totalPrice }}</div>
      </li>
      <li item="領貨方式">
        <div class="text">{{ deliveryType }}</div>
      </li>

      <!-- //TODO: 補回跟團者的取貨地址 -->
      <!-- <template v-if="isDeliveryTypePersonally">
        <li item="取貨地址">
          <div class="text">{{ group.pickup_location.location }}</div>
        </li>
      </template> -->
    </ul>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {
  props: {
    group: {
      required: true,
      type: Object
    }
  },
  computed: {
    memberCount() {
      return get(this.group, 'members_count');
    },
    itemsCount() {
      return get(this.group, 'item_count');
    },
    totalPrice() {
      return get(this.group, 'total_price');
    },
    deliveryType() {
      return get(this.group, 'delivery_type_display');
    },
    isDeliveryTypePersonally() {
      return isEmpty(this.group) ? false : this.group.delivery_type === 1;
    }
  }
};
</script>
