import { mapActions, mapGetters } from 'vuex';
import {
  redirectAfterAuth,
  removeRedirectTo,
  setRedirectTo
} from '@/utils/redirect-to';
import queryString from 'querystring';

export default {
  watch: {
    userIsLogin: {
      immediate: false,
      async handler(newVal) {
        if (newVal === true) {
          await this.getInfo();

          if (this.loginCallback) {
            this.loginCallback();
          }
        } else if (this.lineLiffMode) {
          this.lineLIFFAppInit();
        } else if (window.location.pathname !== '/login') {
          //為了在login page 也能用此mixin
          this.$router.replace('/login');
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userIsLogin']),
    lineLiffMode() {
      return (
        process.env.VUE_APP_OAUTH_PROVIDER === 'line' &&
        this.$liff !== undefined &&
        this.$liff.isInClient()
      );
    },
    //line 社群 openchat 無法取得使用者資訊, liff.access_token 無效
    isInLineSquareChat() {
      return (
        this.lineLiffMode && this.$liff.getContext().type === 'square_chat'
      );
    }
  },
  methods: {
    ...mapActions('user', ['login', 'getInfo']),
    async liffAccessTokenLogin() {
      // if (this.$liff === undefined) {
      //   this.$router.replace('/login');
      //   return;
      // }
      // console.log(this.$route);
      //未登入的話先授權
      if (this.$liff.isLoggedIn() === false) {
        // setRedirectTo(window.location.href);
        this.$liff.login({
          redirectUri: window.location.href
        });
        return;
      }
      let accessToken = this.$liff.getAccessToken();
      await this.login({
        code: accessToken
      });
      removeRedirectTo();
      return;
    },
    //login
    async lineLIFFAppInit() {
      if (this.userIsLogin === false) {
        await this.liffAccessTokenLogin();
      }
    },
    setRedirectTo() {
      //為了在login page 也能用此mixin
      var queryObj = queryString.parse(window.location.search.replace('?', ''));

      if (queryObj) {
        var query = '?' + new URLSearchParams(queryObj).toString();
      }

      setRedirectTo(window.location.pathname + query);
    }
  },
  async mounted() {
    if (this.userIsLogin) {
      if (this.loginCallback) {
        this.loginCallback();
      }
    } else if (this.lineLiffMode && !this.isInLineSquareChat) {
      this.setRedirectTo();

      this.lineLIFFAppInit();
    } else if (window.location.pathname !== '/login') {
      this.setRedirectTo();

      this.$router.replace('/login');
    }
  }
};
